import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    data: []
  },
  mutations: {
    setData(state, payload) {
        state.data = payload; // Mutations để thay đổi state data
    }
  },
  actions: {
    fetchData({ commit }) {
      // Sử dụng Axios để lấy dữ liệu từ API
      axios.get('https://ergast.com/api/f1/drivers.json')
        .then(response => {
          // Xử lý dữ liệu nhận được từ API và commit mutation để thay đổi state
          commit('setData', response.data);
        })
        .catch(error => {
          // Xử lý lỗi nếu có
        });
    },
    fetchDataRace({ commit }) {
        // Sử dụng Axios để lấy dữ liệu từ API
        axios.get('http://localhost:3000/race')
          .then(response => {
            // Xử lý dữ liệu nhận được từ API và commit mutation để thay đổi state
            commit('setData', response.data);
          })
          .catch(error => {
            // Xử lý lỗi nếu có
          });
    }
  },
  getters: {
    getData(state) {
      return state.data; // Getter để lấy dữ liệu từ state
    }
  }
});

export default store;
